import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setMenuOpen(false); // Close the menu after clicking a link
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link onClick={() => handleScroll('registration')} to="/" className="navbar-title-link">
          <h1>NYC V2X and AI Symposium</h1>
        </Link>
      </div>
      <div className={`navbar-right ${menuOpen ? 'open' : ''}`}>
        <ul>
          {/* <li onClick={() => handleScroll('registration')}><Link to="/">Registration </Link></li> */}
          {/* <li><Link to="/">Registration </Link></li> */}
          <li><Link to="/schedule">Schedule </Link></li>
          <li><Link to="/location">Location </Link></li>
          <li><Link to="/speakers">Speakers </Link></li>
          {/* <li><Link to="/info">Info </Link></li> */}
          <li><Link to="/sponsors">Sponsors </Link></li>
      {/* <a href='https://forms.gle/SCQnkfLgFFpVLjEU9' className="register-button">
        Register
      </a> */}
        </ul>
      </div>
      <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
        &#9776;
      </button>
    </nav>
  );
};

export default Navbar;
