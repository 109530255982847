import React, { useState, useEffect, useRef, forwardRef } from 'react';
import Papa from 'papaparse';
import { useLocation } from 'react-router-dom';

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
// import Accordion from "../../components/Accordion";
import './SpeakersPage.css'
import Speaker from './Speaker';


const Accordion = forwardRef(({ title, speakers, moderators, isExpanded, speakerData }, ref) => {
  const [isOpen, setIsOpen] = useState(isExpanded);

  const toggleAccordion = () => {
      setIsOpen(!isOpen);
  };

  const getSpeakerDetails = (name) => {
      return speakerData.find(speaker => speaker['Speaker Name'].trim() === name.trim());
  };

  // Split speakers and moderators by semicolons and trim the result
  const speakerList = speakers ? speakers.split(';').map(getSpeakerDetails).filter(Boolean) : [];
  const moderatorList = moderators ? moderators.split(';').map(getSpeakerDetails).filter(Boolean) : [];

  return (
      <div className="accordion" ref={ref}>
          <div className="accordion-header" onClick={toggleAccordion}>
              <h3>{title}</h3>
          </div>
          {isOpen && (
              <div className="accordion-content">
                  {speakerList.length > 0 && (
                      <div>
                          <h4>Speakers:</h4>
                          {speakerList.map((speaker, index) => (
                              <Speaker
                                  key={index}
                                  name={speaker['Speaker Name']}
                                  title={speaker['Title']}
                                  role={speaker['Role']}
                                  session={speaker['Session']}
                                  bio={speaker['Bio']}
                                  image={speaker.image}
                              />
                          ))}
                      </div>
                  )}
                  {moderatorList.length > 0 && (
                      <div>
                          <h4>Moderators:</h4>
                          {moderatorList.map((moderator, index) => (
                              <Speaker
                                  key={index}
                                  name={moderator['Speaker Name']}
                                  title={moderator['Title']}
                                  role={moderator['Role']}
                                  session={moderator['Session']}
                                  bio={moderator['Bio']}
                                  image={moderator.image}
                              />
                          ))}
                      </div>
                  )}
              </div>
          )}
      </div>
  );
});



const SpeakersPages = () => {
  const [scheduleData, setScheduleData] = useState([]);
    const [speakersData, setSpeakersData] = useState([]);
    const [expandedSession, setExpandedSession] = useState('');
    const accordionRefs = useRef([]);
    const location = useLocation();
    const clickedSpeaker = location.state?.speakerName || null;

    useEffect(() => {
        // Fetch speakers data
        Papa.parse('/speakers.csv', {
            download: true,
            header: true,
            complete: (result) => {
                const parsedData = result.data.map(speaker => ({
                    ...speaker,
                    image: `/headshots/${speaker['Speaker Name']}.jpg`
                }));
                setSpeakersData(parsedData);
            }
        });

        // Fetch schedule data
        Papa.parse('/schedule.csv', {
            download: true,
            header: true,
            complete: (result) => {
                setScheduleData(result.data);
                if (clickedSpeaker) {
                    const session = result.data.find(data => 
                        (data['Speakers']?.includes(clickedSpeaker) || data['Moderators']?.includes(clickedSpeaker))
                    );
                    if (session) {
                        setExpandedSession(session['Session']);
                    }
                }
            }
        });
    }, [clickedSpeaker]);

    useEffect(() => {
        if (clickedSpeaker && accordionRefs.current.length) {
            const refIndex = scheduleData.findIndex(
                session => session['Speakers']?.includes(clickedSpeaker) || session['Moderators']?.includes(clickedSpeaker)
            );
            if (refIndex !== -1 && accordionRefs.current[refIndex]) {
                accordionRefs.current[refIndex].scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [scheduleData, clickedSpeaker]);

    return (
        <div className="speakers-page">
          <Navbar />
            <h2>Speakers</h2>
            {scheduleData.map((session, index) => (
                <Accordion
                    key={index}
                    title={session['Session']}
                    speakers={session['Speakers']}
                    moderators={session['Moderators']}
                    isExpanded={expandedSession === session['Session']}
                    speakerData={speakersData} // Pass the speakers data to Accordion
                    ref={el => accordionRefs.current[index] = el} // Capture the ref for scrolling
                />
            ))}
        </div>
    );
};


export default SpeakersPages;
