// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#schedule {
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

#schedule img {
    width: 60vw;
    margin: 50px;
}

#schedule h1 {
    font-size: 2rem;
}

@media (max-width: 1000px) {
    #schedule img {
        width: 100vw;
        margin: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Schedule.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,YAAY;QACZ,SAAS;IACb;AACJ","sourcesContent":["\n#schedule {\n    align-items: center;\n    padding-top: 60px;\n    padding-bottom: 60px;\n}\n\n#schedule img {\n    width: 60vw;\n    margin: 50px;\n}\n\n#schedule h1 {\n    font-size: 2rem;\n}\n\n@media (max-width: 1000px) {\n    #schedule img {\n        width: 100vw;\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
