import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from '../pages/HomePage/Homepage';
import SponsorsPage from '../pages/SponsorsPage/SponsorsPage';
import InfoPage from '../pages/InfoPage/InfoPage'
import SchedulePage from '../pages/SchedulePage/SchedulePage'
import SpeakersPage from '../pages/SpeakersPage/SpeakersPage';
import SpeakersPages from '../pages/SpeakersPage/SpeakersPages';
import SchedulePages from '../pages/SchedulePage/SchedulePages';
import LocationPage from '../pages/LocationPage/LocationPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/schedule" element={<SchedulePage />} />
          <Route path="/speakers" element={<SpeakersPage />} />
          {/* <Route path="/info" element={<InfoPage />} /> */}
          <Route path="/sponsors" element={<SponsorsPage />} />
          <Route path="/location" element={<LocationPage />} />
        </Routes>
    </Router>
    </div>
  );
}

export default App;
