import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Schedule from "../../components/Schedule";
import './SchedulePage.css'
import { useNavigate } from 'react-router-dom';

const Accordion = ({ title, speakers, moderators, onSpeakerClick }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion">
            <div className="accordion-header" onClick={toggleAccordion}>
                <h3>{title}</h3>
            </div>
            {isOpen && (
                <div className="accordion-content">
                    {speakers && (
                        <div>
                            <h4>Speakers:</h4>
                            <ul>
                                {speakers.split(';').map((speaker, index) => (
                                    <li key={index} onClick={() => onSpeakerClick(speaker.trim())}>
                                        {speaker.trim()}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {moderators && (
                        <div>
                            <h4>Moderators:</h4>
                            <ul>
                                {moderators.split(';').map((moderator, index) => (
                                    <li key={index} onClick={() => onSpeakerClick(moderator.trim())}>
                                        {moderator.trim()}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};


const SchedulePages = () => {
    const [scheduleData, setScheduleData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        Papa.parse('/schedule.csv', {
            download: true,
            header: true,
            complete: (result) => {
                setScheduleData(result.data);
            }
        });
    }, []);

    const handleSpeakerClick = (speaker) => {
        navigate('/speakers', { state: { speakerName: speaker.trim() } });
    };

    return (
        <div className="schedule-page">
            <Navbar />
            <h2>Schedule</h2>
            {scheduleData.map((session, index) => (
                <Accordion
                    key={index}
                    title={`${session['Session']} (${session['Session Time']})`}
                    speakers={session['Speakers']}
                    moderators={session['Moderators']}
                    onSpeakerClick={handleSpeakerClick}
                />
            ))}
            <Footer />
        </div>
    );
};

export default SchedulePages;
