import React, { useEffect, useState, useRef } from 'react';
import Papa from 'papaparse';
import Speaker from './Speaker';
import './Speakers.css';
import { useLocation } from 'react-router-dom';

const Speakers = () => {
    const [scheduleData, setScheduleData] = useState([]);
    const [speakersData, setSpeakersData] = useState([]);
    const [groupedSpeakers, setGroupedSpeakers] = useState({});
    const [expandedSession, setExpandedSession] = useState(null);
    const location = useLocation();
    const speakerRefs = useRef({});
    const sessionRefs = useRef({});

    const checkImageExists = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
        });
    };

    useEffect(() => {
        // Fetch schedule data
        Papa.parse('/schedule.csv', {
            download: true,
            header: true,
            complete: (result) => {
                const sessions = result.data.filter((session) => session['Session Time']);
                setScheduleData(sessions);
            },
        });

        // Fetch speakers data
        Papa.parse('/speakers.csv', {
            download: true,
            header: true,
            complete: (result) => {
                const parsedData = result.data.map((speaker) => ({
                    ...speaker,
                    image: `/headshots/${speaker['Speaker Name']}.jpg`,
                }));
                setSpeakersData(parsedData);
            },
        });
    }, []);

    useEffect(() => {
        const setupGroupedSpeakers = async () => {
            if (scheduleData.length > 0 && speakersData.length > 0) {
                const grouped = {};
                for (const session of scheduleData) {
                    const sessionTitle = session['Session'].trim();
                    const sessionTime = session['Session Time']?.trim() || '';
                    const sessionInfo = session['Session Info']?.trim() || '';
                    const speakersList = [];
                    const speakers = session['Speakers']?.split(';').map((s) => s.trim()) || [];
                    const moderators = session['Moderators']?.split(';').map((s) => s.trim()) || [];

                    const allParticipants = [...speakers, ...moderators];

                    for (const personName of allParticipants) {
                        const personData = speakersData.find(
                            (speaker) => speaker['Speaker Name'].trim() === personName
                        );
                        if (personData) {
                            const imageUrl = personData.image;
                            const imageExists = await checkImageExists(imageUrl);
                            if (imageExists) {
                                speakersList.push(personData);
                            }
                        }
                    }

                    if (speakersList.length > 0) {
                        grouped[sessionTitle] = {
                            sessionTime,
                            sessionInfo,
                            speakers: speakersList,
                        };
                    }
                }

                setGroupedSpeakers(grouped);

                const defaultSession = location.state?.session?.trim() || Object.keys(grouped)[0];
                setExpandedSession(defaultSession);
            }
        };

        setupGroupedSpeakers();
    }, [scheduleData, speakersData]);

    useEffect(() => {
        if (location.state?.speakerName && location.state?.session) {
            setTimeout(() => {
                const speakerNameKey = location.state.speakerName.trim().toLowerCase();
                const sessionTitleKey = location.state.session.trim().toLowerCase();
                const refKey = `${speakerNameKey}-${sessionTitleKey}`;
                const speakerElement = speakerRefs.current[refKey];
                if (speakerElement) {
                    speakerElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }, 0);
        }
    }, [groupedSpeakers]);

    const toggleAccordion = (session) => {
        const newSession = expandedSession === session ? null : session;
        setExpandedSession(newSession);

        // Scroll to the session header
        setTimeout(() => {
            const sessionKey = session.trim().toLowerCase();
            const sessionElement = sessionRefs.current[sessionKey];
            if (sessionElement) {
                const yOffset = -100; // Adjust this value based on your header height
                const yPosition =
                    sessionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: yPosition, behavior: 'smooth' });
            }
        }, 0);
    };

    return (
        <div id="speakers" className="speakers-section">
            <h2 className="info-title">Speakers & Moderators</h2>
            {Object.keys(groupedSpeakers).map((sessionTitle, index) => {
                const sessionData = groupedSpeakers[sessionTitle];
                const sessionKey = sessionTitle.trim().toLowerCase();
                return (
                    <div key={index} className="session-accordion">
                        <div
                            className="session-header"
                            onClick={() => toggleAccordion(sessionTitle)}
                            ref={(el) => {
                                if (el) {
                                    sessionRefs.current[sessionKey] = el;
                                }
                            }}
                        >
                            <div className="session-info">
                                <div className="session-info-left">
                                    <h3 style={{ paddingLeft: "15px" }}>{sessionTitle + " " + sessionData.sessionTime}</h3>
                                </div>
                                <div className="session-info-right">
                                    {sessionData.sessionInfo && <p style={{ fontSize: "1.2rem" }}>{sessionData.sessionInfo}</p>}
                                </div>
                            </div>
                        </div>
                        {expandedSession === sessionTitle && (
                            <div className="session-content">
                                {sessionData.speakers.map((speaker, idx) => {
                                    const speakerNameKey = speaker['Speaker Name'].trim().toLowerCase();
                                    const refKey = `${speakerNameKey}-${sessionKey}`;
                                    return (
                                        <div
                                            key={idx}
                                            ref={(el) => {
                                                if (el) {
                                                    speakerRefs.current[refKey] = el;
                                                }
                                            }}
                                        >
                                            <Speaker
                                                name={speaker['Speaker Name']}
                                                title={speaker['Title']}
                                                role={speaker['Role']}
                                                session={speaker['Session']}
                                                bio={speaker['Bio']}
                                                image={speaker.image}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Speakers;
