// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speakers-section {
    padding: 50px 20px;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .speakers-container {
    min-height: 75vh;
  }
  
  .info-title {
    font-size: 36px;
    margin-bottom: 40px;
    color: #333;
  }
  
  .speaker {
    display: block;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .speaker-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .speaker h3 {
    font-size: 24px;
    margin: 10px 0;
    color: #444;
  }
  
  .speaker p {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
  }
  
  .speaker strong {
    font-weight: 600;
    color: #333;
  }

  @media (max-width: 768px) {
    .speaker {
        width: 100%;
        margin: 20px 0;
    }
  
    .speaker-image {
        width: 120px;
        height: 120px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/SpeakersPage/Speaker.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,cAAc;IACd,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,WAAW;EACb;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE;QACI,WAAW;QACX,cAAc;IAClB;;IAEA;QACI,YAAY;QACZ,aAAa;IACjB;EACF","sourcesContent":[".speakers-section {\n    padding: 50px 20px;\n    background-color: #f4f4f4;\n    text-align: center;\n  }\n  \n  .speakers-container {\n    min-height: 75vh;\n  }\n  \n  .info-title {\n    font-size: 36px;\n    margin-bottom: 40px;\n    color: #333;\n  }\n  \n  .speaker {\n    display: block;\n    width: 100%;\n    max-width: 800px;\n    margin: 20px auto;\n    padding: 20px;\n    background-color: white;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  .speaker-image {\n    width: 150px;\n    height: 150px;\n    border-radius: 50%;\n    object-fit: cover;\n    margin-bottom: 15px;\n  }\n  \n  .speaker h3 {\n    font-size: 24px;\n    margin: 10px 0;\n    color: #444;\n  }\n  \n  .speaker p {\n    font-size: 16px;\n    line-height: 1.5;\n    color: #666;\n  }\n  \n  .speaker strong {\n    font-weight: 600;\n    color: #333;\n  }\n\n  @media (max-width: 768px) {\n    .speaker {\n        width: 100%;\n        margin: 20px 0;\n    }\n  \n    .speaker-image {\n        width: 120px;\n        height: 120px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
