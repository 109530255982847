import React from "react";
import "./Footer.css"

function Footer() {
    return <footer className="footer">
        <div className="footer-images">
            Hosted by
            <img src="/tandon_long_white.png" alt="NYU Tandon Logo" className="footer-logo" />
            and
            <img src="/C2SMARER logo_trans_white.png" alt="C2SMARTER logo" className="footer-logo" />
        </div>
        <p>© {new Date().getFullYear()} C2SMARTER</p>
    </footer>
}

export default Footer;