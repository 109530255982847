// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-section {
    padding: 40px;
    border-radius: 10px;
    margin: 0 auto;
    text-align: center;
    color: #333;
}

#info p {
    margin: 30px 20vw;
}

.info-title {
    font-size: 2rem;
    margin-bottom: 20px;
}

.info-details {
    font-size: 1.2rem;
}

.info-item {
    margin-bottom: 15px;
}

.info-item p {
    margin: 5px 0 0 0;
}

.map-link {
    display: inline-block;
    margin-top: 5px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.map-link:hover {
    text-decoration: underline;
}

.map-container {
    margin-top: 20px;
    text-align: center;
}

@media (max-width: 1000px) {
    #info p {
        margin: 30px 10vw;
    }
}

@media (max-width: 700px) {
    #info p {
        margin: 30px 0;
    }

    .map-container iframe {
        width: 100%;
        height: 300px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Info.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,cAAc;IACd,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,WAAW;QACX,aAAa;IACjB;AACJ","sourcesContent":[".info-section {\n    padding: 40px;\n    border-radius: 10px;\n    margin: 0 auto;\n    text-align: center;\n    color: #333;\n}\n\n#info p {\n    margin: 30px 20vw;\n}\n\n.info-title {\n    font-size: 2rem;\n    margin-bottom: 20px;\n}\n\n.info-details {\n    font-size: 1.2rem;\n}\n\n.info-item {\n    margin-bottom: 15px;\n}\n\n.info-item p {\n    margin: 5px 0 0 0;\n}\n\n.map-link {\n    display: inline-block;\n    margin-top: 5px;\n    color: #007bff;\n    text-decoration: none;\n    font-weight: bold;\n}\n\n.map-link:hover {\n    text-decoration: underline;\n}\n\n.map-container {\n    margin-top: 20px;\n    text-align: center;\n}\n\n@media (max-width: 1000px) {\n    #info p {\n        margin: 30px 10vw;\n    }\n}\n\n@media (max-width: 700px) {\n    #info p {\n        margin: 30px 0;\n    }\n\n    .map-container iframe {\n        width: 100%;\n        height: 300px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
