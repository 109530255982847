import React from 'react';
import './Sponsors.css';

const sponsorLogos = [
  'logo1.png',
  'logo2.png',
  'logo3.png',
  'logo4.png',
  'logo5.gif',
  'logo6.png'
];

const Sponsors = () => {
  return (
    <div id='sponsor' className="sponsors-section">
      <h2>Thank You to our 2024 Symposium Sponsors</h2>
      <div className="sponsors-grid">
        {sponsorLogos.map((logo, index) => (
          <div key={index} className="sponsor-item">
            <img src={`${process.env.PUBLIC_URL}/sponsors/${logo}`} alt={`Sponsor ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sponsors;
