// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metric {
    background-color: rgba(47, 62, 81, 0.8);
    color: white;
    border: none !important;
    margin: 1px;
    display: inline-block;
    font-size: 1em;
    padding: .5em;
    min-width: 100px;
    min-height: 100px;
    opacity: 1;
    text-align: center;
}
.metric p {

    display: block;
}

.metric-label {
    font-size: .75rem;
}
.time-block {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin: 1px;
    display: flex;
    flex-direction: column; /* Align the children vertically */
    justify-content: center;
    align-items: center;
    width: max(2rem, 4vw); /* Adjust the size as needed */
    height: max(2rem, 4vw); /* Make it square by setting the height equal to the width */
}

.time-value {
    font-size: max(1.5rem, 2vw);
}

.time-type {
    font-size: max(0.5rem, 0.9vw); /* Smaller size for the time-type */
}
`, "",{"version":3,"sources":["webpack://./src/components/TimeDisplay.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,YAAY;IACZ,uBAAuB;IACvB,WAAW;IACX,qBAAqB;IACrB,cAAc;IACd,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,UAAU;IACV,kBAAkB;AACtB;AACA;;IAEI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,oCAAoC;IACpC,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB,EAAE,kCAAkC;IAC1D,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB,EAAE,8BAA8B;IACrD,sBAAsB,EAAE,4DAA4D;AACxF;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B,EAAE,mCAAmC;AACtE","sourcesContent":[".metric {\n    background-color: rgba(47, 62, 81, 0.8);\n    color: white;\n    border: none !important;\n    margin: 1px;\n    display: inline-block;\n    font-size: 1em;\n    padding: .5em;\n    min-width: 100px;\n    min-height: 100px;\n    opacity: 1;\n    text-align: center;\n}\n.metric p {\n\n    display: block;\n}\n\n.metric-label {\n    font-size: .75rem;\n}\n.time-block {\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 20px;\n    margin: 1px;\n    display: flex;\n    flex-direction: column; /* Align the children vertically */\n    justify-content: center;\n    align-items: center;\n    width: max(2rem, 4vw); /* Adjust the size as needed */\n    height: max(2rem, 4vw); /* Make it square by setting the height equal to the width */\n}\n\n.time-value {\n    font-size: max(1.5rem, 2vw);\n}\n\n.time-type {\n    font-size: max(0.5rem, 0.9vw); /* Smaller size for the time-type */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
