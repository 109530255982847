import React, { forwardRef } from 'react';

import './Speaker.css'


const Speaker = forwardRef(({ name, title, role, session, bio, image }, ref) => {
    return (
        <div className="speaker" ref={ref}>
            <img src={image} alt={`${name}'s headshot`} className="speaker-image" />
            <h3>{name}</h3>
            <p><strong>{title}</strong></p>
            {/* <p>{role}</p>
            <p><strong>Session:</strong> {session}</p> */}
            <p>{bio}</p>
        </div>
    );
});

export default Speaker;