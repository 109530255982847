import React from "react";
import './Schedule.css'

function Schedule() {
    return <section id="schedule">
    <h1>Event Schedule</h1>
    <img src="./schedule.jpg" alt="Event Schedule"/>
  </section>
}

export default Schedule;