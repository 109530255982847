import React, { useState, useEffect } from 'react';
import './Registration.css';
import TimeDisplay from './TimeDisplay';

const Registration = () => {

    const divStyle = {
        minHeight: `max(700px, calc(0.59 * 100vw))`,
      };
      
    const calculateTimeLeft = () => {
        const eventDate = new Date('2024-10-17T09:00:00');
        const now = new Date();
        const difference = eventDate - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <div id='registration' className="registration-section" style={divStyle}>
            <video autoPlay muted loop>
                <source src="/cover.mov" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="overlay" style={{ marginBottom: '50px' }}>
                <h1 className='title'>NYC V2X and AI Symposium</h1>
            </div>
            {Object.keys(timeLeft).length > 0 && (
                <div className="countdown">
                    <TimeDisplay value={timeLeft.days} type='Days' />
                    <TimeDisplay value={timeLeft.hours} type='Hours' />
                    <TimeDisplay value={timeLeft.minutes} type='Minutes' />
                    <TimeDisplay value={timeLeft.seconds} type='Seconds' />
                </div>
            )}
            {/* {Object.keys(timeLeft).length <= 0 &&  (<span className='overlay'>Event Started</span>)} */}
            {/* <div className="overlay">
                <div className="countdown">
                    {Object.keys(timeLeft).length > 0 ? (
                        <div>
                            <span>{timeLeft.days} Days </span>
                            <span>{timeLeft.hours} Hours </span>
                            <span>{timeLeft.minutes} Minutes </span>
                            <span>{timeLeft.seconds} Seconds</span>
                        </div>
                    ) : (
                        <span>Event Started</span>
                    )}
                </div>
            </div> */}
            {/* <div className="register-btn disabled">
                For last-minute, on-site registration please contact Lizzie Pohl at <a href="mailto:ejp321@nyu.com">ejp321@nyu.com</a>. 
                Please provide your full name and email.
            </div> */}
            {/* <div className="overlay">
            <p style={{margin: "0", fontSize: "16px", fontStyle: "italic"}}>Registration is free, but all attendees are required to register.</p>
            </div> */}

            {/* https://forms.gle/SCQnkfLgFFpVLjEU9 */}
            <div className="location-overlay overlay">
                October 17th-18th, 2024
                <br />
                Pfizer Auditorium, NYU Tandon School of Engineering
                <br />
                5 MetroTech, Brooklyn, NY
            </div>
        </div>
    );
};

export default Registration;
