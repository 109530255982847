import './Homepage.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Registration from '../../components/Registration';
// import Schedule from '../../components/Schedule';
import Info from '../../components/Info';
import Sponsors from '../../components/Sponsors';
import Speakers from '../SpeakersPage/Speakers';
import Schedule from '../SchedulePage/Schedule';

function Homepage() {
  return (
    <div className="HomePage">
      <Navbar />
      <Registration />
      <Schedule />
      {/* <Speakers />
      <Info /> */}
      {/* <Sponsors /> */}
      <Footer />
    </div>
  );
}

export default Homepage;
