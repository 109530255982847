import React from 'react';

const Location = () => {
    return (
        <div id='location' className="info-section">
            <h2 className="info-title">Location</h2>
            <div className="info-details">
                <div className="info-item">
                    {/* <p><strong>Venue:</strong></p> */}
                    {/* <span>Makerspace, </span> */}
                    <a
                        href="https://www.google.com/maps?q=5+Metrotech"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="map-link"
                    >
                        Pfizer Auditorium, 5 MetroTech, Brooklyn, NY
                    </a>
                </div>
            </div>
            <div className="map-container">
                <iframe
                    loading="lazy"
                    src="https://maps.google.com/maps?q=Pfizer%20Auditorium%2C%205%20Metrotech%20Center%2C%20Brooklyn%2C%20NY%2011201&amp;t=m&amp;z=18&amp;output=embed&amp;iwloc=near"
                    title="Pfizer Auditorium"
                    aria-label="Pfizer Auditorium"
                    width="600"
                    height="450"
                ></iframe>
            </div>

        </div>
    );
};

export default Location;
