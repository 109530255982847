// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SponsorsPage {
    margin-top: 10vh;
  }


  .partnership-section {
    text-align: center;
    margin: 20px 0;
    width: 100%;
  }
  
  .partnership-section h2 {
    margin-bottom: 20px;
  }
  
  .partnership-section iframe {
    border: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/SponsorsPage/SponsorsPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;;EAGA;IACE,kBAAkB;IAClB,cAAc;IACd,WAAW;EACb;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,YAAY;EACd","sourcesContent":[".SponsorsPage {\n    margin-top: 10vh;\n  }\n\n\n  .partnership-section {\n    text-align: center;\n    margin: 20px 0;\n    width: 100%;\n  }\n  \n  .partnership-section h2 {\n    margin-bottom: 20px;\n  }\n  \n  .partnership-section iframe {\n    border: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
