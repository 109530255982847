// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpeakersPage {
    /* margin-top: 10vh; */
    padding-top: 10vh;
  }`, "",{"version":3,"sources":["webpack://./src/pages/SpeakersPage/SpeakersPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,iBAAiB;EACnB","sourcesContent":[".SpeakersPage {\n    /* margin-top: 10vh; */\n    padding-top: 10vh;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
