import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
// import Schedule from "../../components/Schedule";
import Schedule from "./Schedule";
import './SchedulePage.css'

function SponsorsPage() {
    return <div className="SchedulePage">
        <Navbar />
        <Schedule />
        <Footer />
    </div>;
}

export default SponsorsPage;