import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Info from "../../components/Info";
import './InfoPage.css'

function SponsorsPage() {
    return <div className="InfoPage">
        <Navbar />
        <Info />
        <Footer />
    </div>;
}

export default SponsorsPage;