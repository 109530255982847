import React from "react";
import './TimeDisplay.css'

function TimeDisplayBlock(props) {
    return <span className="time-block">
        <span className="time-value">{props.value}</span>
        <span className="time-type">{props.type}</span>
    </span>
}

export default TimeDisplayBlock;