import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import './LocationPage.css'
import Location from "./Location";

function LocationPage() {
    return <div className="LocationPage">
        <Navbar />
        <Location />
        <Footer />
    </div>;
}

export default LocationPage;