import './Schedule.css';
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';

const Accordion = ({ title, info, speakers, moderators, onSpeakerClick, isClickable, isDay, session, speakersData, slidesData }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        if (isClickable) setIsOpen(!isOpen);
    };

    // Check if a person is in the speakersData
    const isPersonInSpeakersData = (person) => {
        return speakersData.some(speaker => speaker['Speaker Name'].trim() === person.trim());
    };

    // Check if there's a title to display as a button based on the slidesData
    const getSpeakerTitle = (speaker, session) => {
        const match = slidesData.find(item =>
            item.NAME.trim() === speaker.trim() &&
            item.SESSION.trim() === session['Session Info'].trim() &&
            item['OKed by Presenter'] === 'x' &&
            item['LINK to PRES']
        );
        return match ? { title: match.TITLE, link: match['LINK to PRES'] } : null;
    };

    return (
        <div className={`accordion ${isClickable ? '' : 'non-clickable'}`} style={{ borderBottom: isDay && "1px solid #ccc" }}>
            <div className={`accordion-header ${isDay ? '' : 'session-header'}`} onClick={toggleAccordion}>
                <div className="session-info-left">
                    {isDay ? <h2 style={{ fontWeight: "bold", marginBottom: "5px" }}>{title}</h2> : <h3 style={{ paddingLeft: "15px" }}>{title}</h3>}
                </div>
                <div className="session-info-right">
                    <p style={{ fontSize: "1.2rem" }}>{info}</p>
                </div>
            </div>
            {isOpen && isClickable && (
                <div className="accordion-content">
                    {speakers && (
                        <div>
                            <h4>Speakers:</h4>
                            <ul>
                                {speakers.split(';').map((speaker, index) => {
                                    const personExists = isPersonInSpeakersData(speaker.trim());
                                    const speakerTitleData = getSpeakerTitle(speaker.trim(), session);
                                    return (
                                        <li
                                            key={index}
                                            onClick={personExists ? () => onSpeakerClick(speaker.trim(), session) : null}
                                            className={personExists ? 'infoExists' : 'infoNotExists'}
                                        >
                                            {speaker.trim()}
                                            {speakerTitleData && (
                                                <button
                                                    className="title-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(speakerTitleData.link, '_blank');
                                                    }}
                                                >
                                                    {speakerTitleData.title}
                                                </button>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    {moderators && (
                        <div>
                            <h4>Moderators:</h4>
                            <ul>
                                {moderators.split(';').map((moderator, index) => {
                                    const personExists = isPersonInSpeakersData(moderator.trim());
                                    return (
                                        <li
                                            key={index}
                                            onClick={personExists ? () => onSpeakerClick(moderator.trim(), session) : null}
                                            className={personExists ? 'infoExists' : 'infoNotExists'}
                                        >
                                            {moderator.trim()}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const Schedule = () => {
    const [scheduleData, setScheduleData] = useState([]);
    const [speakersData, setSpeakersData] = useState([]);
    const [slidesData, setSlidesData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch schedule data
        Papa.parse('/schedule.csv', {
            download: true,
            header: true,
            complete: (result) => {
                setScheduleData(result.data);
            }
        });

        // Fetch speakers data
        Papa.parse('/speakers.csv', {
            download: true,
            header: true,
            complete: (result) => {
                setSpeakersData(result.data);
            }
        });

        // Fetch V2X Slides data
        Papa.parse('/slides.csv', {
            download: true,
            header: true,
            complete: (result) => {
                setSlidesData(result.data);
            }
        });
    }, []);

    const handleSpeakerClick = (speaker, session) => {
        // Pass both the speaker name and session information to location.state
        navigate('/speakers', {
            state: {
                speakerName: speaker.trim(),
                session: session['Session']
            }
        });
    };

    return (
        <div className="schedule-container">
            <h1>Event Schedule</h1>
            <a href="/NYC V2X and AI Symposium.pdf" target="_blank" rel="noopener noreferrer">
                Click to view the detailed agenda
            </a>
            {scheduleData.map((session, index) => {
                const isClickable = session['Speakers'] || session['Moderators'];
                const isDay = !session['Session Time'];

                // Check for time and only include parentheses if there's time
                const title = isDay
                    ? session['Session']
                    : `${session['Session']} (${session['Session Time']})`;

                return (
                    <div key={index} className={isDay ? 'day-separator' : ''}>
                        <Accordion
                            title={title}
                            info={session['Session Info'] || ''}
                            speakers={session['Speakers']}
                            moderators={session['Moderators']}
                            onSpeakerClick={handleSpeakerClick}
                            isClickable={isClickable}
                            isDay={isDay}
                            session={session} // Pass the session object to the Accordion
                            speakersData={speakersData} // Pass the speakers data to check clickability
                            slidesData={slidesData} // Pass the slides data for conditional title display
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Schedule;
