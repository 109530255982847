// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sponsors.css */

.sponsors-section {
    /* background-color: #d8f2fb; */
    background-color: #f9f9f9;
    padding: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #sponsor h2 {
    margin-bottom: 50px;
  }
  
  .sponsors-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 80px;
    row-gap: 100px;
    max-width: min(90%, 800px);
  }
  
  .sponsor-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sponsor-item img {
    width: 180px;
    height: auto;
    object-fit: contain;
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/Sponsors.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;IACI,+BAA+B;IAC/B,yBAAyB;IACzB,aAAa;IACb,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,2DAA2D;IAC3D,SAAS;IACT,cAAc;IACd,0BAA0B;EAC5B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,mBAAmB;EACrB","sourcesContent":["/* Sponsors.css */\n\n.sponsors-section {\n    /* background-color: #d8f2fb; */\n    background-color: #f9f9f9;\n    padding: 20px;\n    padding-bottom: 50px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n\n  #sponsor h2 {\n    margin-bottom: 50px;\n  }\n  \n  .sponsors-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));\n    gap: 80px;\n    row-gap: 100px;\n    max-width: min(90%, 800px);\n  }\n  \n  .sponsor-item {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .sponsor-item img {\n    width: 180px;\n    height: auto;\n    object-fit: contain;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
