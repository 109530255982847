// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px;
}

.footer-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between the logos */
    margin-bottom: 10px;
}

.footer-logo {
    max-width: 150px;
    height: auto;
}

footer p {
    margin: 0;
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS,EAAE,4BAA4B;IACvC,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,aAAa;AACjB","sourcesContent":[".footer {\n    background-color: #333;\n    color: white;\n    text-align: center;\n    padding: 20px;\n}\n\n.footer-images {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 20px; /* Space between the logos */\n    margin-bottom: 10px;\n}\n\n.footer-logo {\n    max-width: 150px;\n    height: auto;\n}\n\nfooter p {\n    margin: 0;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
