import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Sponsors from "../../components/Sponsors";
import './SponsorsPage.css'

function SponsorsPage() {
    return <div className="SponsorsPage">
        <Navbar />
        <Sponsors />
        {/* <div className="partnership-section">
            <h2>Interested in Partnering with Us?</h2>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSfZP1mVbmEoP2xx3duEDFaF3g-8BFIWNWvTjGqt98v6S-0mTw/viewform?embedded=true"
                width="100%"
                height="1250"
                title="Google Form for Partnering with Us">
                Loading…
            </iframe>
        </div> */}
        <Footer />
    </div>;
}

export default SponsorsPage;