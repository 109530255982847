import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Speakers from "./Speakers";
import './SpeakersPage.css'

function SpeakersPage() {
    return <div className="SpeakersPage">
        <Navbar />
        <Speakers />
        <Footer />
    </div>;
}

export default SpeakersPage;