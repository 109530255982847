import React from 'react';
import './Info.css';

const Info = () => {
    return (
        <div id='info' className="info-section">
            <h2 className="info-title">Location</h2>
            <div className="info-details">
                {/* <div className="info-item">
                    <strong>Start:</strong>
                    <p>October 17 @ 10:00 am</p>
                </div>
                <div className="info-item">
                    <strong>End:</strong>
                    <p>October 18 @ 5:00 pm</p>
                </div> */}
                <div className="info-item">
                    {/* <p><strong>Venue:</strong></p> */}
                    {/* <span>Makerspace, </span> */}
                    <a
                        href="https://www.google.com/maps?q=5+Metrotech"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="map-link"
                    >
                        Pfizer Auditorium, 5 MetroTech, Brooklyn, NY
                    </a>
                </div>
            </div>
            <div className="map-container">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.9045291523994!2d-73.98605898459407!3d40.69337837933509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a317dcb269b%3A0xb015f271b55ab8ae!2sPfizer%20Auditorium%2C%205%20MetroTech%20Center%2C%20Brooklyn%2C%20NY%2011201%2C%20USA!5e0!3m2!1sen!2sus!4v1609989859161!5m2!1sen!2sus"
                    width="600"
                    height="450"
                    allowFullScreen=""
                    loading="lazy"
                    title="Google Map"
                ></iframe>
            </div>
        </div>
    );
};

export default Info;
